<template>
  <div class="table-container">
    <VcaTable
      store="user/emails/mailbox"
      :pagination="mailbox + '_pg'"
      :list="getList()"
      :colspan="5"
      :searchLabel="$t('email.list.search')"
    >
      <template v-slot:colspan>
        <col width="24%" />
        <col width="24%" />
        <col width="24%" />
        <col width="24%" />
        <col width="1%" />
      </template>
      <template v-slot:desktop-filter v-if="getFilters.length > 0">
        <vca-accordion :header="$t('email.list.filter')">
          <div slot="body">
            <EmailFilter :filters="getFilters" v-model="filter" />
          </div>
        </vca-accordion>
      </template>

      <template v-slot:desktop-header>
        <th class="vca-table-cell sortable" @click="sort('subject')">
          <label> {{ $t("email.list.subject") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('from')">
          <label> {{ $t("email.list.from") }} &varr;</label>
        </th>
        <th
          class="vca-table-cell sortable"
          @click="sort('recipient_group_label')"
        >
          <label>
            {{ $t("email.list.recipient_group.label") }}
            &varr;</label
          >
        </th>
        <th class="vca-table-cell sortable" @click="sort('modified.created')">
          <label> {{ $t("email.list.created") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>

      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
        >
          <td class="vca-table-cell">
            <label> {{ res.subject }} </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.from }} </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ res.recipient_group_label }}
              <div
                class="inline-infobox"
                v-if="res.recipient_group_info"
                style="color: black"
              >
                <vca-info-box
                  ><div v-html="res.recipient_group_info"></div
                ></vca-info-box></div
            ></label>
          </td>
          <td class="vca-table-cell">
            <label> {{ date(res.modified.created) }} </label>
          </td>
          <td class="vca-table-cell">
            <label class="table-options">
              <img
                v-if="mailbox == 'draft'"
                class="editable"
                src="@/assets/icons/edit.png"
                @click="setCurrentEdit(res)"
                :title="$t('button.edit')"
                :alt="$t('button.edit')"
              />
              <img
                @click="setCurrent(res)"
                class="editable apply-filter"
                src="@/assets/icons/public.svg"
                :title="$t('button.view')"
                :alt="$t('button.view')"
              />
            </label>
          </td>
        </tr>
      </template>

      <template v-slot:mobile-filter v-if="getFilters.length > 0">
        <button class="vca-button" @click="do_filter = true">
          {{ $t("email.list.filter") }}
        </button>
        <vca-popup
          v-if="do_filter"
          :show="do_filter"
          :title="$t('email.list.filter')"
          @close="do_filter = false"
        >
          <EmailFilter :filters="getFilters" v-model="filter" />
        </vca-popup>
      </template>

      <template v-slot:mobile-header>
        <div><img width="20px" src="~@/assets/icons/sort.png" /></div>
        <div class="sortable" @click="sort('subject')">
          <label>{{ $t("email.list.subject") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('recipient_group_label')">
          <label>{{ $t("email.list.recipient_group.label") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('modified.created')">
          <label>{{ $t("email.list.created") }} &varr;</label>
        </div>
      </template>

      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          @click="setCurrent(res)"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-column>
              <vca-row>
                <div class="vca-left vca-table-index">
                  <span class="bold">{{ res.subject }}</span>
                </div>
                <div class="vca-right vca-table-index">
                  {{ date(res.modified.updated) }}
                </div>
              </vca-row>
              <vca-row>
                {{ res.recipient_group_label }}
              </vca-row>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
import EmailFilter from "@/components/emails/list/EmailFilter";
export default {
  name: "EmailDraftTable",
  components: { VcaTable, EmailFilter },
  data() {
    return {
      store: "user/emails/mailbox/" + this.mailbox + "_pg",
      initial: true,
      do_filter: false,
      currentFilter: {},
      currentTextFilter: "",
    };
  },
  props: {
    mailbox: {
      type: String,
      default: "draft",
    },
  },
  watch: {
    pg: {
      handler(val) {
        this.$store.commit(this.store + "/pagination", val);
      },
      deep: true,
    },
  },
  created() {
    this.filter = {
      active_state: [],
      nvm_state: [],
      event_state: [],
    };
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
      list(state, getters) {
        if (this.store) {
          return getters[`user/emails/mailbox/${this.mailbox}`];
        }
      },
    }),
    ...mapGetters({
      getEvent: "user/aspevents/getEvent",
      getCrew: "crews/getCrew",
      create: "user/emails/mailbox/message/create",
    }),
    emptyFilter() {
      return (
        (!this.pg.filter || this.pg.filter == "") &&
        this.filter.active_state.length == 0 &&
        this.filter.nvm_state.length == 0 &&
        this.filter.event_state.length == 0
      );
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
    getFilters() {
      const unique = [
        ...new Set(this.list.map((item) => item.recipient_group.type)),
      ]; // [ 'A', 'B']
      return unique;
    },
  },
  methods: {
    getRecipientGroup(res) {
      if (!res.recipient_group.type) {
        return this.$t("email.preview.missing_receivers");
      }

      if (res.recipient_group.type == "crew") {
        var crew = this.getCrew(res.recipient_group.crew_id);
        if (!crew) {
          return "";
        }
        return this.$t(
          "email.preview.recipient_group." + res.recipient_group.type,
          { 0: crew.name }
        );
      } else {
        const event = this.getEvent(res.recipient_group.event_id);
        return this.$t(
          "email.preview.recipient_group." + res.recipient_group.type,
          { 0: event ? event.name : "" }
        );
      }
    },
    getRecipientInfo(res) {
      if (!res.recipient_group.type) {
        return this.$t("email.preview.missing_receivers");
      }

      if (res.recipient_group.type == "crew") {
        return (
          this.$t("email.membership.active.short") +
          (res.recipient_group.active_state &&
          res.recipient_group.active_state.length > 0
            ? res.recipient_group.active_state
                .map((state) => this.$t("email.membership.active." + state))
                .join(", ")
            : this.$t("email.membership.active.all_selected")) +
          "<br>" +
          this.$t("email.membership.nvm.short") +
          (res.recipient_group.nvm_state &&
          res.recipient_group.nvm_state.length > 0
            ? res.recipient_group.nvm_state
                .map((state) => this.$t("email.membership.nvm." + state))
                .join(", ")
            : this.$t("email.membership.nvm.all_selected"))
        );
      } else {
        return res.recipient_group.state
          .map((state) => this.$t("email.event.group." + state))
          .join(", ");
      }
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("user/emails/mailbox/message/current", null);
      } else {
        this.$store.commit("user/emails/mailbox/message/current", value);
      }
    },
    setCurrentEdit(value) {
      this.$router.push({
        path: "/account/asps/emails/edit/" + value.id,
      });
    },
    getList() {
      // If the list is empty, we have an empty array
      if (!this.list) {
        return [];
      }

      // If the current page is out of scope, set it to last page
      if (this.pg.currentPage > this.pg.pageCount) {
        this.$store.commit(this.store + "/prevPage");
      }

      // Create a list for filtering and start filtering
      var filteredList = this.list;
      filteredList = filteredList.filter((row, index) => {
        // Add a rank to the current entry
        row.rank = index + 1;
        row.recipient_group_label = this.getRecipientGroup(row);
        row.recipient_group_info = this.getRecipientInfo(row);
        // If the filter is empty, everything is fine
        if (this.emptyFilter) {
          return true;
        }

        if (
          this.filter.active_state.length > 0 &&
          this.filter.active_state.length < 4
        ) {
          if (row.recipient_group.active_state == null) {
            return false;
          }
          if (
            !this.filter.active_state.find((el) =>
              row.recipient_group.active_state.includes(el)
            )
          ) {
            return false;
          }
        }
        if (
          this.filter.nvm_state.length > 0 &&
          this.filter.nvm_state.length < 3
        ) {
          if (row.recipient_group.nvm_state == null) {
            return false;
          }
          if (
            !this.filter.nvm_state.find((el) =>
              row.recipient_group.nvm_state.includes(el)
            )
          ) {
            return false;
          }
        }
        if (
          this.filter.event_state.length > 0 &&
          this.filter.event_state.length < 3
        ) {
          if (row.recipient_group.state == null) {
            return false;
          }
          if (
            !this.filter.event_state.find((el) =>
              row.recipient_group.state.includes(el)
            )
          ) {
            return false;
          }
        }

        // Filter for the current value of row.full_name etc.
        return (
          row.subject.toLowerCase().indexOf(this.pg.filter.toLowerCase()) >
            -1 ||
          row.from.toLowerCase().indexOf(this.pg.filter.toLowerCase()) > -1
        );
      });

      // Set the length of the list to the current filtered list
      this.pg.listLength = filteredList.length == 0 ? 1 : filteredList.length;
      if (
        this.pg.listLength < this.pg.pageSize ||
        this.pg.pageSize > filteredList.length
      ) {
        // If the length is smaller than the current page size, we adjust the pagesize to the length of the filtered list
        this.pg.pageSize = this.pg.listLength;
      } else if (!this.sameFilter || this.initial) {
        // If the filter has changed, we resize the list
        this.initial = false;
        this.pg.pageSize =
          this.pg.currentPageSize > this.pg.listLength
            ? this.pg.listLength
            : this.pg.currentPageSize;
      }
      // Set the new filters
      if (!this.sameFilter) {
        this.currentTextFilter = this.pg.filter;
        this.currentFilter = JSON.parse(JSON.stringify(this.filter));
      }

      // Now we filter the list to the current pageSize and paginate it
      return filteredList.filter((row, index) => {
        let start = (this.pg.currentPage - 1) * this.pg.pageSize;
        let end = this.pg.currentPage * this.pg.pageSize;
        if (index >= start && index < end) return true;
      });
    },
    sort(col) {
      // When sorting, we set the current page to 1
      this.$store.commit(this.store + "/firstPage");

      // If we have an empty list, return an empty array
      if (!this.list) {
        return [];
      }

      // Define the sorting direction, if current sort is the same as the column to sort, then switch direction
      if (this.pg.currentSort === col) {
        this.pg.currentSortDir =
          this.pg.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.pg.currentSort = col;
      }

      // Sort the list depending on the column and the direction
      this.list.sort((a, b) => {
        var final_key = this.pg.currentSort;

        // Sort nested objects if sorting column contains a dot (.)
        if (col.indexOf(".") !== -1) {
          var column = col.split(".");
          var len = column.length;
          var i = 0;
          while (i < len - 1) {
            a = a[column[i]];
            b = b[column[i]];
            i++;
            final_key = column[i];
          }
        }

        // Set zero 0 at the end
        // if (parseInt(a[final_key]) == 0) return 1;
        // if (parseInt(b[final_key]) == 0) return -1;

        // Comapre integer values
        if (
          a[final_key] === parseInt(a[final_key], 10) ||
          a[final_key] === parseFloat(a[final_key], 10)
        ) {
          return this.pg.currentSortDir === "desc"
            ? a[final_key] - b[final_key]
            : b[final_key] - a[final_key];
        }

        // Compare string values
        let modifier = 1;
        if (this.pg.currentSortDir === "desc") modifier = -1;
        if (a[final_key].toLowerCase() < b[final_key].toLowerCase())
          return -1 * modifier;
        if (a[final_key].toLowerCase() > b[final_key].toLowerCase())
          return 1 * modifier;
        return 0;
      });
    },
  },
};
</script>
